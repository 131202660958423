import React from 'react';
import Hero from './sections/hero/Hero';
import RssBlogFeed from './sections/rss-blogs/RssBlogFeed';

const Blog = () => {
  return (
    <>
      <Hero />
      <RssBlogFeed />
    </>
  );
};

export default Blog;
