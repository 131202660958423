import React from 'react';
import './Objective.scss';
import Illustration from '../../../../assets/about-objectives.png';

const Objective = () => {
  return (
    <section className='container-fluid about-objective px-4 px-md-5'>
      <div className='row g-5'>
        <div className='col-md-4 align-center'>
          <img
            src={Illustration}
            alt='connect, create, belong'
            className='img-fluid'
            data-aos='fade-up'
          />
        </div>
        <div className='col-md-8 align-center'>
          <div className='content'>
            <h1 className='mb-4' data-aos='fade-in'>Our Objective: Happiness</h1>
            <p data-aos='fade-in'>
              In an age where traditional social media platforms embrace
              practices that erode the essence of community, Anokha stands as a
              beacon of change. We challenge actions like paid verifications,
              exorbitant API costs, content barriers, and algorithms that
              perpetuate toxicity.
            </p>
            <p data-aos='fade-in'>
              At Anokha, we are inspired by the boundless possibilities of a
              more positive, empowering, community-driven digital world. We are
              on a mission to onboard 100 Million users by 2030 by
              revolutionizing digital interactions, celebrating creators, and
              fostering a culture of authenticity.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Objective;
