// src/components/RssFeed.js

import React, { useEffect, useState } from 'react';
import './RssBlogFeed.scss';
import { parseDate } from './dateParser';
import defaultCover from '../../../../assets/blog-default.png';
import noData from '../../../../assets/empty.png';

const mediumRssFeed =
  'https://api.rss2json.com/v1/api.json?rss_url=https://rss.beehiiv.com/feeds/1Y8hmWkvJi.xml';

const RssBlogFeed = () => {
  const MAX_ARTICLES = 6;

  const [articles, setArticles] = useState();

  useEffect(() => {
    const loadArticles = async () => {
      fetch(mediumRssFeed, { headers: { Accept: 'application/json' } })
        .then(res => res.json())
        .then(data => data.items.filter(item => item.title.length > 0))
        .then(newArticles => newArticles.slice(0, MAX_ARTICLES))
        .then(articles => setArticles(articles))
        .catch(error => console.log(error));
    };

    loadArticles();
  }, [MAX_ARTICLES]);

  return (
    <section className='container-fluid rss-blog'>
      <div className='container'>
        <div className='row g-5'>
          {articles ? (
            articles.map((item, index) => (
              <div className='col-md-4' key={index}>
                <a href={item.link}>
                  <div className='card'>
                    <img
                      src={
                        item.enclosure.link ? item.enclosure.link : defaultCover
                      }
                      className='card-img-top'
                      alt={item.title}
                    />
                    <div className='card-body'>
                      <h5 className='card-title'>{item.title}</h5>
                      <p className='card-text mb-5 blog-desc'>
                        {item.description}
                      </p>
                      <span className='text-secondary'>
                        {parseDate(item.pubDate)}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            ))
          ) : (
            <div className='text-center'>
              <img src={noData} alt='no blogs' className='img-fluid mb-3' />
              <p className='text-center'>No blog found</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default RssBlogFeed;
