import React from 'react';
import './Stats.scss';
import unicef from '../../../../assets/unicef.png';
import welcome from '../../../../assets/welcome.jpeg';
import mha from '../../../../assets/mha.png';
import { useInView } from 'react-intersection-observer';

const Stats = () => {
  const { ref, inView, entry } = useInView();
  return (
    <section className='container-fluid stats px-4 px-md-5' id='safety'>
      <div className='first-stat'>
        <header className='content'>
          <h2 className='section-title' data-aos="fade-in">
            <strong>Why did we create Anokha?</strong>
          </h2>
          <h2 className='section-description' data-aos='fade-in' data-aos-delay="200">
            Traditional social media is fueling a mental health epidemic by
            optimizing algorithms for toxicity, gore, violence, and NSFW
            content. The data is evident.
          </h2>
        </header>
        <div
          className='stats-container d-flex flex-column gap-3 gap-md-5 mb-4'
          ref={ref}
        >
          <div>
            <div className='stat stat-1'>
              <div className={`stat-progress ${inView ? 'animate' : ''}`}>
                <h4>79%</h4>
              </div>
            </div>
            <p className='stat-description' data-aos='fade-up'>
              Adults believe online harassment and bullying is being addressed
              poorly.
            </p>
          </div>
          <div>
            <div className='stat stat-2'>
              <div className={`stat-progress ${inView ? 'animate' : ''}`}>
                <h4>52%</h4>
              </div>
            </div>
            <p className='stat-description' data-aos='fade-up'>
              Adults have personally experienced online harassment.
            </p>
          </div>
          <div>
            <div className='stat stat-3'>
              <div className={`stat-progress ${inView ? 'animate' : ''}`}>
                <h4>51%</h4>
              </div>
            </div>
            <p className='stat-description' data-aos='fade-up'>
              Teens have experienced online harassment.
            </p>
          </div>
        </div>
        <p className='stats-source' data-aos="fade-in">
          Data from, “The State of Online Harassment,” Pew Research Center.
          January 2021.
        </p>
      </div>
      <div className='content below'>
        <h2 data-aos='fade-in' className='section-description'>
          At Anokha, mental health is <span>not just</span> a trending topic.
        </h2>
        <h2 className='section-description' data-aos='fade-in'>
          Our team has contributed to the world of mental health research,
          activism, and policy alongside:
        </h2>
      </div>
      <div className='anokha-card mental-health-sources'>
        <div className='row g-md-5'>
          <div className='col-12 col-md-4 justify-center'>
            <img src={mha} alt='mental health america' />
          </div>
          <div className='col-12 col-md-4 justify-center'>
            <img src={"https://getlucidity.com/wp-content/uploads/2021/03/wellcometrust.webp"} alt='welcome' />
          </div>
          <div className='col-12 col-md-4 justify-center'>
            <img src={unicef} alt='unicef' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
