import React from 'react';
import './Footer.scss';
import logo from '../../assets/footer-logo.svg';
import twitter from '../../assets/twitter.svg';
import linkedin from '../../assets/linkedin.svg';
import facebook from '../../assets/facebook.svg';
import vic from '../../assets/vic.svg';
import dribble from '../../assets/dribble.svg';
import github from '../../assets/github.svg';
import ScrollToTop from '../scroll-top/ScrollToTop';
import { Link } from 'react-router-dom';

const productList = [
  {
    name: 'Overview',
    link: '#',
  },
  {
    name: 'Features',
    link: '#',
  },
  {
    name: 'Solutions',
    isNew: true,
    link: '#',
  },
  {
    name: 'Tutorials',
    link: '#',
  },
  {
    name: 'Pricing',
    link: '#',
  },
  {
    name: 'Releases',
    link: '#',
  },
];
const companyList = [
  {
    name: 'About us',
    link: '/about',
  },
  {
    name: 'Careers',
    target: "_blank",
    link: 'https://anokha.notion.site/Job-Board-1e91f254120c4d7ba2c504098b05213a?pvs=4',
  },
  // {
  //   name: 'Press',
  //   link: '#',
  // },
  // {
  //   name: 'News',
  //   link: '#',
  // },
  // {
  //   name: 'Media kit',
  //   link: '#',
  // },
  {
    name: 'Contact',
    link: 'mailto:hello@anokha.xyz',
  },
];
const resourcesList = [
  {
    name: 'Blog',
    route: '/blog',
  },
  {
    name: 'Newsletter',
    target: "_blank",
    link: 'https://anokha.beehiiv.com/',
  },
  // {
  //   name: 'Events',
  //   link: '#',
  // },
  // {
  //   name: 'Help centre',
  //   link: '#',
  // },
  // {
  //   name: 'Tutorials',
  //   link: '#',
  // },
  {
    name: 'Support',
    link: 'mailto:support@anokha.xyz',
  },
];
const legalList = [
  {
    name: 'Terms',
    route: '/terms-and-conditions',
  },
  {
    name: 'Privacy',
    route: '/privacy-policy',
  },
  // {
  //   name: 'Cookies',
  //   link: '#',
  // },
  // {
  //   name: 'Licenses',
  //   link: '#',
  // },
  // {
  //   name: 'Settings',
  //   link: '#',
  // },
  {
    name: 'Contact',
    link: 'mailto:hello@anokha.xyz',
  },
];

const footerItems = (item, index) => {
  return (
    <li key={index}>
      {item.link ? (
        <a href={item.link} target={item.target}>
          <p>{item.name}</p>
        </a>
      ) : (
        <Link to={item.route}>
          <p>{item.name}</p>
        </Link>
      )}
    </li>
  );
};
const Footer = () => {
  return (
    <>
      <ScrollToTop />
      <footer className='container-fluid footer'>
        <div className='container px-4 px-md-0'>
          <div className='row'>
            <div className='col-md-4'>
              <img src={logo} alt='anokha' className='mb-3' />
              <p className='text-secondary mb-5'> © {new Date().getFullYear()} Anokha. All rights reserved.</p>
            </div>
            {/* <div className='col-6 col-md-2 footer-nav-col'>
              <h5>Product</h5>
              <ul>
                {productList.map((item, index) => footerItems(item, index))}
              </ul>
            </div> */}
            <div className='col-6 col-md-2 footer-nav-col'>
              <h5>Company</h5>
              <ul>
                {companyList.map((item, index) => footerItems(item, index))}
              </ul>
            </div>
            <div className='col-6 col-md-2 footer-nav-col'>
              <h5>Resources</h5>
              <ul>
                {resourcesList.map((item, index) => footerItems(item, index))}
              </ul>
            </div>
            <div className='col-6 col-md-2 footer-nav-col'>
              <h5>Legal</h5>
              <ul>
                {legalList.map((item, index) => footerItems(item, index))}
              </ul>
            </div>
          </div>
        </div>
        <div className='foot-bar d-flex align-items-center justify-content-between px-md-5 px-3 py-4 flex-column flex-md-row gap-3'>
          <p className='f-small'>Website Co-Produced by <a target="_blank" href='http://xerolabs.xyz/'>XeroLabs</a>.</p>
          <ul className='d-flex gap-4 list-unstyled'>
            <li>
              <a href="https://twitter.com/anokhaxyz" target="_blank">
                <img src={twitter} alt='twitter' />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/anokhaxyz" target="_blank">
                <img src={linkedin} alt='linkedin' />
              </a>
            </li>
            <li>
              <a href="https://github.com/anokhaxyz" target="_blank">
                <img src={github} alt='github' />
              </a>
            </li>
            {/* <li>
              <img src={vic} alt='vic' />
            </li>
            <li>
              <img src={dribble} alt='dribble' />
            </li> */}
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
