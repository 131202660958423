import React from 'react';
import './Intro.scss';
import girl from '../../../../assets/girl.png';
import star from '../../../../assets/shooting-star.png';

const individualCard = (
  <div className='flip-card' tabIndex='0' data-aos='fade-up'>
    <div className='flip-card-inner green'>
      <div className='flip-card-front justify-center'>
        <div className='content'>
          <h3>INDIVIDUALS</h3>
          <img src={girl} alt='girl' />
        </div>
      </div>
      <div className='flip-card-back justify-center'>
        <p>
          Follow and engage with your friends, personalize your feed, customize
          your posts, discover trending topics, share all your thoughts, photos,
          videos, and more.
        </p>
      </div>
    </div>
  </div>
);

const communityCard = (
  <div className='flip-card' tabIndex='0' data-aos='fade-up' data-aos-delay='200'>
    <div className='flip-card-inner pink'>
      <div className='flip-card-front justify-center'>
        <div className='content'>
          <h3>COMMUNITIES</h3>
          <img src={star} alt='star' />
        </div>
      </div>
      <div className='flip-card-back justify-center'>
        <p>
        Whether you’re an artist, gamer, or just looking to belong,
          communities helps you share your interests and enjoy live events,
          collaborative content, games, and more.
        </p>
      </div>
    </div>
  </div>
);

const Intro = () => {
  return (
    <section className='container-fluid intro px-0 px-md-5' id='intro'>
      <header className='content px-4 px-md-0'>
        <h2 className='section-title' data-aos='fade-in'>
          <strong>What is Anokha?</strong>
        </h2>
        <h2 className='section-description' data-aos='fade-in'>
          <span>Anokha is a new age social media platform</span>{' '}
          <br className='d-none d-md-inline-block' />{' '}
          <strong>
            celebrating positivity, empowering creators, and fostering
            community-first experiences.
          </strong>
        </h2>
      </header>
      <div className='row g-5 d-none d-md-flex px-4 px-md-0'>
        <div className='col-md-6'>{individualCard}</div>
        <div className='col-md-6'>{communityCard}</div>
      </div>
      <div className='d-md-none'>
        <div className="intro-slide">
          {individualCard}
          {communityCard}
        </div>
      </div>
    </section>
  );
};

export default Intro;
