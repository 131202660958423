import React from 'react';
import './Inspiration.scss';
import Illustration from '../../../../assets/about-inspire.png';

const Inspiration = () => {
  return (
    <section className='container-fluid about-inspiration px-4 px-md-5'>
      <div className='row g-5'>
        <div className='col-md-8 align-center order-1 order-md-0'>
          <div className='content'>
            <h1 className='mb-4' data-aos='fade-in'>Inspired by community</h1>
            <p data-aos='fade-in'>
              Anokha, founded by Satvik Sethi in 2023, is born from a deep
              passion for mental health advocacy and social impact. While at
              college, inspired by his personal experiences and driven by a
              desire to create positive change, he founded Runaway, a community
              dedicated to providing free mental health resources to students
              around the world.
            </p>
            <p data-aos='fade-in'>
              Satvik's commitment to social impact has led him to work with
              organizations such as UNICEF, Mental Health America, and Wellcome
              Trust to co-author studies, conduct research, and co-create
              policies. He also interned with Nobel laureate Kailash Satyarthi,
              further igniting his dedication to social service and awareness.
            </p>
            <p data-aos='fade-in'>
              At Anokha, our mission is rooted in fostering genuine
              conversations, connecting individuals, and supporting mental
              well-being.
            </p>
          </div>
        </div>
        <div className='col-md-4 align-center order-0 order-md-1'>
          <img
            src={Illustration}
            alt='connect, create, belong'
            className='img-fluid'
            data-aos='fade-up'
          />
        </div>
      </div>
    </section>
  );
};

export default Inspiration;
