import React from 'react';
import Objective from './sections/objective/Objective';
import Inspiration from './sections/inspiration/Inspiration';
import Values from './sections/values/Values';
import AboutContent from './sections/hero/AboutContent';
import Hero from '../blog/sections/hero/Hero';

const About = () => {
  return (
    <>
      <Hero title='About' />
      <AboutContent/>
      <Objective />
      <Inspiration />
      <Values />
    </>
  );
};

export default About;
