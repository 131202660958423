import React from 'react';
import './Hero.scss';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';

//assets
import leftMagnet from '../../../../assets/hero-magnet-left.svg';
import topHeart from '../../../../assets/hero-hearts-top.svg';
import topMessage from '../../../../assets/hero-message-top.svg';
import redIcon from '../../../../assets/hero-icon-right.svg';
import rightMagnet from '../../../../assets/hero-magnet-right.svg';
import bottomHeart from '../../../../assets/hero-heart-bottom.svg';
import bottomMessage from '../../../../assets/hero-message-bottom.svg';
import leftHand from '../../../../assets/hero-hand-left.svg';

const Hero = ({title = 'Blog', spacing}) => {
  return (
    <section className='container-fluid blog-hero'>
      <NavigationBar />
      <div className={`content-wrapper justify-center ${spacing ? 'spaced' : ''}`}>
        <header className='content'>
          <h1>{title}</h1>
        </header>
        <img
          src={leftMagnet}
          className='hero-illustration left-magnet'
          alt='magnet that attracts community'
        />
        <img
          src={topHeart}
          className='hero-illustration top-heart'
          alt='community hearts'
        />
        <img
          src={topMessage}
          className='hero-illustration top-message'
          alt='text box message'
        />
        <img
          src={redIcon}
          className='hero-illustration right-red'
          alt='user in community'
        />
        <img
          src={rightMagnet}
          className='hero-illustration right-magnet'
          alt='magnet that attracts community'
        />
        <img
          src={bottomHeart}
          className='hero-illustration bottom-heart'
          alt='hearts'
        />
        <img
          src={bottomMessage}
          className='hero-illustration bottom-message'
          alt='message box'
        />
        <img
          src={leftHand}
          className='hero-illustration left-hand'
          alt='raise hand with phone'
        />
      </div>
    </section>
  );
};

export default Hero;
