import React from 'react'
import Hero from '../blog/sections/hero/Hero'

const PrivacyPolicy = () => {
  return (
    <><Hero title='Privacy Policy' spacing />
    <div className='px-0 px-md-5 container-fluid bg-white'>
        <section>
          <p className='text-start'>
          <h3>1. Introduction</h3>
            Welcome to Anokha! Anokha is a social platform designed to foster meaningful connections and conversations among its users. This Privacy Policy is intended to help you understand how we collect, use, disclose, and safeguard your information to provide you with the best possible user experience. We value your privacy and are committed to protecting your personal information.
            <br />
            By using Anokha, you agree to the practices described in this Privacy Policy. Please take the time to read and familiarize yourself with this document. If you do not agree with our Privacy Policy, please do not use Anokha.
          </p>


          <p className='text-start'>
          <h3>2. Information We Collect</h3>
            We collect a variety of information to provide you with a seamless and personalized experience on Anokha. The information we collect falls into the following categories:
            <br />
            a. Account Information:
            <br />
            When you create an Anokha account, we may collect information such as your username, password, email address, phone number, date of birth, and Ethereum cryptocurrency wallet address. You also have the option to provide your name and nicknames.
            <br />
            b. Content You Create:
            <br />
            As a user of Anokha, you can create various types of content, including text, images, voice messages, custom emojis, and other forms of digital content. These include messages or posts, Sanctum creation details, and profile information.
            <br />
            c. Payment Information:
            <br />
            If you choose to purchase any of our premium services, we will collect necessary payment information, including your full name and billing address. Note that we do not store your full credit card number, only the last four digits, which are processed securely by our payment processors.
            <br />
            d. Information from Actions You Take:
            <br />
            We collect information about your activities on Anokha, including your interactions with friends, users, bots, and apps. This data may include the assets in your wallet, the topics you’re interested in, the communities you join, your roles in Sanctums, and content moderation decisions.
            <br />
            e. Information for Optional Features:
            <br />
            Certain features, such as contact syncing and third-party integrations, may require you to provide additional information or grant access to your existing data from other services. For example, if you link a music streaming account, we may collect information about the songs you are listening to.
            <br />
            f. Other Information You Provide:
            <br />
            You may have the option to submit additional information while using Anokha. For example, participation in our verified Sanctum program, surveys, or communication with our support teams.
          </p>

          <p className='text-start'>
          <h3>3. How We Use Your Information</h3>
          <p>We use your information for various purposes, including:</p>
          <p>a. To Fulfill Our Contract with You:</p>
          <ul>
              <li>To provide you with our services.</li>
              <li>To verify your account.</li>
              <li>To facilitate purchases.</li>
              <li>To create and manage your account.</li>
          </ul>
          <p>b. To Meet Our Commitments to the Anokha Community:</p>
          <ul>
              <li>To maintain a safe and inclusive environment.</li>
              <li>To take action against users and content that violate our Terms of Service.</li>
              <li>To respond to user reports and investigate issues.</li>
              <li>To detect and prevent fraud and malware.</li>
              <li>To proactively scan attachments for illegal or harmful content.</li>
          </ul>
          <p>c. To Contact You:</p>
          <ul>
              <li>To verify and secure your account with two-factor authentication.</li>
              <li>To inform you of important product or policy changes.</li>
              <li>To provide information about products you have purchased.</li>
          </ul>
          <p>d. To Provide Customer Service:</p>
          <ul>
              <li>To respond to questions and investigate issues.</li>
          </ul>
          <p>e. To Protect Our Services:</p>
          <ul>
              <li>To maintain the security of our services.</li>
              <li>To prevent misuse and enforce our Terms of Service.</li>
              <li>To report on our company&apos;s performance.</li>
              <li>To personalize the product.</li>
              <li>To improve our services.</li>
              <li>To advertise our services.</li>
              <li>To comply with our legal obligations.</li>
              <li>To protect someone&apos;s vital interests.</li>
          </ul>
          </p>


          <p className='text-start'>
          <h3>4. How We Disclose Your Information</h3>
          <p>We may share your information in the following ways:</p>
          <p>a. When You Tell Us To:</p>
          <ul>
              <li>When you share content on Anokha, you are granting access to specific people or communities based on the permissions set by Sanctum owners or admins.</li>
          </ul>
          <p>b. With Our Vendors:</p>
          <ul>
              <li>We may provide information to vendors that perform specific tasks for us, such as payment processors and hosting providers.</li>
          </ul>
          <p>c. To Comply with the Law:</p>
          <ul>
              <li>We may disclose information in response to legal requirements, including national security or law enforcement requests. Where allowed and feasible, we will provide you with notice before disclosing your information.</li>
          </ul>
          <p>d. In an Emergency:</p>
          <ul>
              <li>We may disclose information when we believe it is necessary to prevent serious harm to an individual.</li>
          </ul>
          <p>e. To Enforce Our Policies and Rights:</p>
          <ul>
              <li>We may disclose information to enforce our Terms of Service or protect the rights, property, and safety of our users and others.</li>
          </ul>
          <p>f. With Our Related Companies:</p>
          <ul>
              <li>We may share information with our parent, affiliate, subsidiary, and related companies.</li>
          </ul>
          <p>g. Sale, Acquisition, or Transfer of Assets:</p>
          <ul>
              <li>We may disclose information in connection with mergers, acquisitions, reorganizations, bankruptcies, or sales of our assets.</li>
          </ul>
          <p>h. Aggregated or De-identified Information:</p>
          <ul>
              <li>We may share information that is aggregated or anonymized in a way that it cannot reasonably identify you.</li>
          </ul>
          </p>


          <p className='text-start'>
            <h3>5. Data Retention</h3>
            <p>We retain personal information as long as it is needed for the purposes for which it was collected or as required by law. For specific retention periods, please refer to our Data Retention Policy.</p>
          </p>


          <p className='text-start'>
            <h3>6. How We Protect Your Information</h3>
            <p>We take several steps to protect your information:</p>
            <ul>
                <li>All information transmitted on Anokha is encrypted in transit and at rest.</li>
                <li>Technical and administrative access controls limit access to non-public personal information.</li>
                <li>You can enhance the security of your account with two-factor authentication.</li>
            </ul>
          </p>


          <p className='text-start'>
            <h3>7. How to Control Your Privacy</h3>
            <p>We believe that users should have control over their Anokha experience. Here&apos;s how you can manage your information and privacy on Anokha:</p>
            <p>a. Be Aware of Your Anokha Spaces:</p>
            <ul>
                <li>Choose the communities you participate in and what information you share.</li>
            </ul>
            <p>b. Customize Your Personal Settings:</p>
            <ul>
                <li>Adjust your user settings to control how information is used, what is scanned for explicit content, and who can interact with you.</li>
            </ul>
            <p>c. Disable or Delete Your Account:</p>
            <ul>
                <li>Disable your account to stop the processing of new data, which allows you to reactivate your account. Delete your account to permanently remove your information.</li>
            </ul>
            <p>d. Manage Your Content and Sanctums:</p>
            <ul>
                <li>Edit or delete messages and content you&apos;ve created if you have access to the space.</li>
                <li>Edit or delete Sanctums and channels if you have permission to do so.</li>
            </ul>
            <p>e. Access Your Information:</p>
            <ul>
                <li>Request a copy of your data through the Privacy &amp; Safety section of your User Settings.</li>
            </ul>
          </p>


          <p className='text-start'>
            <h3>8. Manage Cookies</h3>
            <p>To control how cookies collect and use information on Anokha:</p>
            <p>a. Browser Settings:</p>
            <ul>
                <li>You can disable and manage some cookies through your browser settings. Please configure your settings for each browser you use.</li>
            </ul>
            <p>b. Disable Analytics Cookies:</p>
            <ul>
                <li>Use browser controls or opt-out mechanisms provided by specific providers, such as Google Analytics Opt-Out.</li>
            </ul>
            <p>c. Mobile Device Settings:</p>
            <ul>
                <li>Your mobile device may have settings to control the collection and use of information in your apps.</li>
            </ul>
            <p>d. Third-Party Groups:</p>
            <ul>
                <li>Limit how cookies are used for advertising purposes by participating third parties.</li>
            </ul>
            <p>e. Cookie Preferences:</p>
            <ul>
                <li>Adjust your cookie preferences through a cookies banner or by selecting &quot;Cookie Settings&quot; on our website, depending on your location.</li>
            </ul>
            <p>Please note that disabling or removing cookies may affect the functionality of Anokha, and your information may still be collected to remember your opt-out preferences.</p>
          </p>


          <p className='text-start'>
            <h3>9. Questions or Concerns</h3>
            <p>If you have questions or concerns about your privacy on Anokha, please contact us at legal@anokha.xyz.</p>
          </p>


          <p className='text-start'>
            <h3>10. International Data Transfers</h3>
            <p>Anokha is based in [Location], and we may process and store information on Sanctums located in different regions. By using Anokha, you acknowledge that your information may be processed, transferred, and stored in regions where different data protection standards apply. We rely on standard contract clauses, adequacy decisions, and other legally compliant mechanisms for data transfers outside your region.</p>
          </p>


          <p className='text-start'>
            <h3>11. Services Offered by Third Parties</h3>
            <p>Third-party developers may offer additional features or services within Anokha, such as bots, games, or integrations. These services are subject to their privacy policies, and you should review them to understand how your information is handled. We do not control these third-party services or the information they collect.</p>
          </p>


          <p className='text-start'>
            <h3>12. Additional Information for Users in Specific Regions</h3>
            <p>a. Information for Users in the European Economic Area (EEA), United Kingdom, Switzerland, and Brazil:</p>
            <p>If you are located in certain regions, including the European Union, you may have additional rights that you can exercise through the Anokha app or by contacting us. These include rights such as access to your personal data, the right to rectify incorrect information, the right to erasure, and more. You can exercise these rights by contacting us at legal@anokha.xyz.</p>
            <p>b. Information for Users in Certain U.S. States:</p>
            <p>If you reside in California, Colorado, Connecticut, Utah, or Virginia, you may have additional rights that you can exercise through the Anokha app or by contacting us. These include rights such as access to specific pieces of personal information, correction of personal information, deletion of personal information, and opting out of profiling. We do not sell your personal data or share it for targeted advertising purposes.</p>
            <p>Please note that certain information may be exempt from the above requests as required by applicable law. We may also need to verify your identity before responding to your request.</p>
          </p>



          <p className='text-start'>
            <h3>13. Changes to this Privacy Policy</h3>
            <p>We may update this Privacy Policy over time. The date of the last update is indicated at the top of the document. In case of significant changes, we will provide you with prominent notice, such as emailing you or highlighting the changes within the services.</p>
          </p>


          <p className='text-start'>
            <h3>14. Contact Us</h3>
            <p>If you have any questions about this Privacy Policy or the way we process your information on Anokha, please feel free to contact us at legal@anokha.xyz. We will be happy to assist you.</p>
            <p>Please remember that this draft is for reference purposes only and should not be considered as a legally binding document. It is essential to have this draft reviewed and adjusted by legal professionals to ensure compliance with all relevant laws and regulations in your jurisdiction.</p>
          </p>
        </section>
      </div></>
  )
}

export default PrivacyPolicy