import React from 'react';
import './Values.scss';
import Illustration from '../../../../assets/about-values.png';

const Values = () => {
  return (
    <section className='container-fluid about-values px-4 px-md-5'>
      <div className='row g-5'>
        <div className='col-md-4 align-center order-1 order-md-0'>
          <img
            src={Illustration}
            alt='connect, create, belong'
            className='img-fluid'
            data-aos='fade-up'
          />
        </div>
        <div className='col-md-8 align-center order-0 order-md-1'>
          <div className='content'>
            <h1 className='mb-4' data-aos='fade-in'>
              Our Values
            </h1>
            <p data-aos='fade-in'>
              At Anokha, we dream of a digital world where authentic connections
              thrive, contributions are valued, and communities flourish. We
              achieve this by steadfastly upholding our core values:
            </p>
            <ul data-aos='fade-in'>
              <li>
                <p>
                  Inclusivity: Anokha is a welcoming space for creators,
                  technologists, community builders, and individuals from all
                  walks of life.
                </p>
              </li>
              <li>
                <p>
                  Empowerment: We empower our users to take control of their
                  digital presence, data, and online experiences.
                </p>
              </li>
              <li>
                <p>
                  Innovation: With over 100 features on our roadmap, we're
                  committed to building groundbreaking tools that redefine the
                  social media landscape.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
