import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import './styles/main.scss';
import Aos from 'aos';
import 'aos/dist/aos.css';
//components
import Footer from './components/footer/Footer';

import 'swiper/css';
import 'swiper/css/pagination';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Blog from './pages/blog/Blog';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Terms from './pages/terms/Terms';

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/blog' element={<Blog />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-and-conditions' element={<Terms />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
