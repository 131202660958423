import React from 'react';
import Hero from '../blog/sections/hero/Hero';

const Terms = () => {
  return (
    <>
      <Hero title='Terms and Conditions' spacing />
      <div className='px-0 px-md-5 container-fluid bg-white'>
        <section>
          <p className='text-start'>
            <h3>Introduction</h3>
            <p>Welcome to Anokha! These Terms of Service (the &quot;Terms&quot;) govern your use of Anokha&apos;s platform, including its website and mobile applications (collectively, the &quot;Services&quot;). By accessing or using the Services, you agree to be bound by these Terms. Please read them carefully. If you do not agree with these Terms, please do not use the Services.</p>
          </p>

          <p className='text-start'>
            <h2>1 Your Use of Anokha</h2>
            <h3>1.1 Eligibility</h3>
            <p>1.1.1 Age Requirement</p>
            <p>To use Anokha, you must be at least 13 years old. If you are under 13, you may not use our Services. By using the Services, you represent and warrant that you are at least 13 years old.</p>
            <p>1.1.2 Capacity</p>
            <p>If you are entering into these Terms on behalf of an organization or entity, you represent and warrant that you have the legal authority to bind such organization or entity to these Terms.</p>
            <h3>1.2 Account Registration</h3>
            <p>1.2.1 Account Creation</p>
            <p>In order to access certain features of the Services, you may be required to register for an account. When creating your account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password.</p>
            <p>1.2.2 Account Responsibility</p>
            <p>You are responsible for all activities that occur under your account. If you discover any unauthorized use of your account, you must immediately notify Anokha.</p>
            <p>1.2.3 Account Termination</p>
            <p>Anokha reserves the right to suspend or terminate your account if it believes that you have violated these Terms or if the account remains inactive for a significant period.</p>
            <h3>1.3 User Conduct</h3>
            <p>1.3.1 Compliance</p>
            <p>You agree to use the Services in compliance with all applicable laws, rules, and regulations. You shall not engage in any illegal or unlawful activities while using the Services.</p>
            <p>1.3.2 Prohibited Conduct</p>
            <p>While using the Services, you agree not to:</p>
            <ul>
                <li>Violate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;</li>
                <li>Defame, stalk, bully, abuse, harass, threaten, impersonate, or intimidate people or entities;</li>
                <li>Post any private or personal information about another user without their consent;</li>
                <li>Interfere with or disrupt the Services or servers or networks connected to the Services;</li>
                <li>Attempt to gain unauthorized access to the Services, user accounts, computer systems, or networks through hacking, password mining, or any other means;</li>
                <li>Conduct any form of &quot;scraping,&quot; &quot;crawling,&quot; or &quot;spidering&quot; the Services or using other automated means to access the Services.</li>
            </ul>
            <p>1.3.3 Impersonation</p>
            <p>You shall not impersonate another individual, organization, or entity when using the Services, and you must provide accurate and truthful information about yourself when registering for an account.</p>
            <p>1.3.4 Unsolicited Communications</p>
            <p>You may not distribute unsolicited commercial messages or engage in any spam-like activity through the Services.</p>
            <p>1.3.5 Fraudulent Activities</p>
            <p>Engaging in fraudulent or deceptive activities is strictly prohibited. This includes, but is not limited to, misrepresenting your identity, purpose, or intent while using the Services.</p>
            <p>1.3.6 Abusive Behavior</p>
            <p>Abusive, threatening, or harassing behavior towards other users is not tolerated. Any such conduct can result in immediate suspension or termination of your account.</p>
            <h2>2. Content on Anokha</h2>
            <h3>2.1 User-Generated Content</h3>
            <p>2.1.1 License</p>
            <p>You retain ownership of the content you submit, post, or display on or through the Services (&quot;User Content&quot;). By posting User Content on or through the Services, you grant Anokha a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute your User Content for the purpose of operating and improving the Services.</p>
            <p>2.1.2 User Content Responsibility</p>
            <p>You are solely responsible for the User Content you post on the Services. You represent and warrant that you have all the necessary rights to grant the licenses set out in these Terms and that your User Content does not infringe any third-party rights.</p>
            <p>2.1.3 Content Removal</p>
            <p>Anokha reserves the right to remove any User Content that violates these Terms or any applicable law or regulation.</p>
            <h3>2.2 Anokha&apos;s Content</h3>
            <p>The Services contain content provided by Anokha (including but not limited to text, information, graphics, logos, images, and data). This content is protected by intellectual property laws. You may not use, copy, or distribute this content without Anokha&apos;s express written consent.</p>
            <h3>2.3 Third-Party Content</h3>
            <p>The Services may feature content from third parties, including other users, partners, or advertisers. Anokha is not responsible for the content provided by third parties.</p>
            <h2>3. Third-Party Services</h2>
            <p>The Services may allow you to access third-party websites or services. Anokha is not responsible for these third-party services. You must review and agree to the terms and conditions of these services, as they may apply to your use of the Services.</p>
            <h2>4. Copyright</h2>
            <p>Anokha respects intellectual property rights and expects you to do the same. If you believe your copyright is being violated on the Services, please follow our Copyright &amp; IP Policy to file a complaint.</p>
            <h2>5. Paid Services</h2>
            <p>5.1 Payment</p>
            <p>Some of our Services may require payment of fees. These paid services are subject to our Paid Services Terms, which are hereby incorporated into these Terms.</p>
            <p>5.2 Refunds</p>
            <p>For paid services, refunds may be provided based on the terms outlined in our Refund Policy.</p>
            <h2>6. User Data and Privacy</h2>
            <p>6.1 Privacy Policy</p>
            <p>Your use of the Services is also governed by our Privacy Policy, which details how Anokha collects, uses, and discloses personal information.</p>
            <p>6.2 Data Security</p>
            <p>Anokha takes measures to secure your data and maintain the privacy of your information. You are responsible for safeguarding your account information and passwords.</p>
            <h2>7. Restrictions</h2>
            <p>7.1 Use Restrictions</p>
            <p>While using the Services, you agree to adhere to the following restrictions:</p>
            <ul>
                <li>You may not violate any applicable law or regulation.</li>
                <li>You may not violate the rights of others, including intellectual property rights.</li>
                <li>You may not engage in abusive, threatening, or harassing conduct.</li>
                <li>You may not distribute unsolicited commercial messages or &quot;spam.&quot;</li>
                <li>You may not engage in any fraudulent or deceptive activities.</li>
                <li>You may not impersonate another person or entity.</li>
            </ul>
            <p>7.2 System Integrity</p>
            <p>You may not take actions that could compromise the integrity of the Services or disrupt the operation of our servers, networks, or systems.</p>
            <h2>8. Termination</h2>
            <h3>8.1 Your Right to Terminate</h3>
            <p>You may stop using the Services at any time by deleting your account.</p>
            <h3>8.2 Anokha&apos;s Right to Terminate</h3>
            <p>Anokha may suspend or terminate your access to the Services if you violate these Terms, pose a risk to other users, or engage in any illegal or unauthorized activities. Anokha may also suspend or terminate your account if it remains inactive for an extended period.</p>
            <h3>8.3 Effect of Termination</h3>
            <p>Upon termination of your account, you will lose all access to the Services, including your User Content. Some provisions of these Terms will continue to apply, including, but not limited to, Sections 2 (Content on Anokha), 4 (Copyright), 5 (Paid Services), 6 (User Data and Privacy), 7 (Restrictions), and 9 (Dispute Resolution).</p>
            <h2>9. Dispute Resolution</h2>
            <h3>9.1 Informal Resolution</h3>
            <p>Most disputes can be resolved informally. If you have a dispute, you agree to reach out to us before initiating a lawsuit or arbitration. This requires sending Anokha a written description of the dispute, including your name, the issue, and how you&apos;d like to resolve it, along with your contact information, to legal@anokha.xyz. If the dispute is not resolved within 60 days after receipt of the written notice, you and Anokha agree to resolve any remaining dispute through the further dispute resolution provisions below.</p>
            <h3>9.2 Governing Law and Jurisdiction</h3>
            <p>These Terms and any disputes related to these Terms or our Services will be governed by the laws of [insert your jurisdiction]. Any disputes that are not subject to arbitration will be resolved exclusively in the state or federal courts in [insert your jurisdiction], and you and Anokha both consent to venue and personal jurisdiction in these courts.</p>
            <h2>10. Changes to these Terms</h2>
            <p>We may decide to update these Terms for various reasons, including changes to our Services, legal requirements, or better user protection. If these changes significantly affect your use of Anokha or your legal rights, we will provide reasonable advance notice. If you continue to use the Services after the changes take effect, it means you agree to the new Terms. If you do not agree, you must stop using our Services.</p>
            <h2>11. Contacting Anokha</h2>
            <p>If you have any questions about these Terms, please contact us at legal@anokha.xyz. We may send you electronic communications related to our Services, and you can opt out of direct marketing as per our Privacy Policy.</p>
            <h2>12. Entire Agreement</h2>
            <p>These Terms constitute the entire agreement between you and Anokha for your use of our Services.</p>
            <h2>13. Additional Terms</h2>
            <p>Where additional terms apply to our products or services, the additional terms will control with respect to your use of that product or service to the extent of any conflict with these Terms.</p>
            <h2>14. Bug Reporting</h2>
            <p>We support the responsible reporting of security vulnerabilities. To report a security issue, please visit [security reporting link].</p>
            <h2>15. Export Control</h2>
            <p>You agree to comply with all applicable import, export, and re-export control laws and restrictions. You may not download or use our Services if you are located in a country or region subject to government embargo unless that use is authorized by the relevant authorities.</p>
            <h2>16. Waiver, Severability, and Assignment</h2>
            <p>If we fail to act on any breach of these Terms, that does not waive our right to act on subsequent or similar breaches. If any part of these Terms becomes invalid or unenforceable, the rest of the Terms will remain in effect. You may not assign these Terms without our written consent, while we may assign our rights to affiliates, subsidiaries, or successors.</p>
            <h2>17. Survival</h2>
            <p>Certain provisions of these Terms, such as disclaimers, limitations of liability, and dispute resolution, will survive termination or expiration.</p>
          </p>
        </section>
      </div>
    </>
  );
};

export default Terms;
