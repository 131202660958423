import React from 'react';
import Hero from './sections/hero/Hero';
import Features from './sections/features/Features';
import Testimonial from './sections/testimonial/Testimonial';
import Communities from './sections/communities/Communities';
import Stats from './sections/stats/Stats';
import Intro from './sections/intro/Intro';

const Home = () => {
  return (
    <>
      <Hero />
      <Intro />
      <Features />
      <Stats />
      {/* <Testimonial /> */}
      {/* <Communities /> */}
    </>
  );
};

export default Home;
