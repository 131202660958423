import React, { useState } from 'react';
import './Hero.scss';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import { MouseParallax, ScrollParallax } from 'react-just-parallax';

//assets
import leftMagnet from '../../../../assets/hero-magnet-left.svg';
import topHeart from '../../../../assets/hero-hearts-top.svg';
import topMessage from '../../../../assets/hero-message-top.svg';
import redIcon from '../../../../assets/hero-icon-right.svg';
import rightMagnet from '../../../../assets/hero-magnet-right.svg';
import bottomUser from '../../../../assets/hero-user-bottom.svg';
import bottomHeart from '../../../../assets/hero-heart-bottom.svg';
import bottomMessage from '../../../../assets/hero-message-bottom.svg';
import leftHand from '../../../../assets/hero-hand-left.svg';
import axios from 'axios';

const Hero = () => {
  const [email, setEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const addToWhiteList = async() => {
    const data = (await axios.post(`https://anokha.woople.io/v1/auth/whitelist/email`, {email})).data;

    if(data.message == "SUCCESS"){
      setResponseMessage("Your mail is added successfully")
    }else{
      setResponseMessage("Oops there is an error")
    }

    setTimeout(() => {
      setResponseMessage("")
    }, 5000);
  }

  return (
    <section className='container-fluid hero' id='home'>
      <NavigationBar />
      <div className='content-wrapper justify-center'>
        <header className='content'>
          <h1>
            <strong>The positive alternative</strong>
            <br />
            <span>to toxic social networks</span>.
          </h1>
          <div className='btn-group mt-4 align-items-center gap-4'>
            <input
              type='email'
              placeholder='Enter your email'
              id='email'
              className='primary'
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className='primary' onClick={addToWhiteList}>Join Waitlist</button>
          </div>
          {responseMessage != "" && <div className='mt-2'>{responseMessage}</div>}
        </header>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.04} lerpEase={0.06}>
          <img
            src={leftMagnet}
            className='hero-illustration left-magnet'
            alt='magnet that attracts community'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.09} lerpEase={0.04}>
          <img
            src={topHeart}
            className='hero-illustration top-heart'
            alt='community hearts'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.06} lerpEase={0.09}>
          <img
            src={topMessage}
            className='hero-illustration top-message'
            alt='text box message'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.02} lerpEase={0.03}>
          <img
            src={redIcon}
            className='hero-illustration right-red'
            alt='user in community'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.02} lerpEase={0.07}>
          <img
            src={rightMagnet}
            className='hero-illustration right-magnet'
            alt='magnet that attracts community'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.04} lerpEase={0.03}>
          <img
            src={bottomUser}
            className='hero-illustration bottom-user'
            alt='community announcement'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.07} lerpEase={0.04}>
          <img
            src={bottomHeart}
            className='hero-illustration bottom-heart'
            alt='hearts'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.03} lerpEase={0.05}>
          <img
            src={bottomMessage}
            className='hero-illustration bottom-message'
            alt='message box'
          />
        </MouseParallax>
        <MouseParallax isAbsolutelyPositioned enableOnTouchDevice strength={0.04} lerpEase={0.07}>
          <img
            src={leftHand}
            className='hero-illustration left-hand'
            alt='raise hand with phone'
          />
        </MouseParallax>
      </div>
    </section>
  );
};

export default Hero;
