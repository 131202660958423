import React from 'react';
import './SectionSlider.scss';

const SectionSlider = ({ items }) => {
  return (
    <div className='custom-slider'>
      {items.map(item => (
        <>{item}</>
      ))}
    </div>
  );
};

export default SectionSlider;
