import React from 'react';
import './Hero.scss';
import HeroImg from '../../../../assets/about-hero.png';

const AboutContent = () => {
  return (
    <section className='container-fluid about-hero px-4 px-md-5'>
      <div className='row g-5'>
        <div className='col-md-8 align-center order-1 order-md-0'>
          <div className='content'>
            <h1 className='mb-4'>Connect, Create, Belong</h1>
            <p>
              Anokha is a transformative social media platform designed for
              individuals and communities who seek genuine connections and
              meaningful conversations. We’re on a mission to revolutionize
              digital interactions by celebrating positivity, empowering
              creators, and prioritizing community-first experiences.
            </p>
          </div>
        </div>
        <div className='col-md-4 align-center order-0 order-md-1'>
          <img
            src={HeroImg}
            alt='connect, create, belong'
            className='img-fluid'
          />
        </div>
      </div>
    </section>
  );
};

export default AboutContent;
