import React, { useEffect, useState } from 'react';
import './ScrollToTop.scss';
import topIcon from '../../assets/top.png';
import { Link } from 'react-scroll';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <Link to={'navigation'} spy={true} smooth={true} duration={500}>
          <button className='scroll-to-top'>
            <img src={topIcon} alt='top' />
          </button>
        </Link>
      )}
    </div>
  );
};

export default ScrollToTop;
