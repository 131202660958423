import React from 'react';
import './Features.scss';
import reward from '../../../../assets/reward.png';
import community from '../../../../assets/community.png';
import planet from '../../../../assets/planet.png';
import SectionSlider from '../../../../components/section-slider/SectionSlider';

const rewardCard = (
  <div className='anokha-card green' data-aos='fade-up'>
    <h3>REWARD <br className='d-md-none' /> BASED</h3>
    <img src={reward} alt='reward' />
    <p>
      Users and communities earn various rewards for their contributions and
      activity.
    </p>
  </div>
);

const communityCard = (
  <div className='anokha-card blue px-0' data-aos='fade-up' data-aos-delay={200}>
    <h3>COMMUNITY <br className='d-md-none' /> DRIVEN</h3>
    <img src={community} alt='community' />
    <p>
      Enjoy tools like positivity filters, topic based networks, and ownership
      communities.
    </p>
  </div>
);

const userCard = (
  <div className='anokha-card yellow' data-aos='fade-up' data-aos-delay={400}>
    <h3>USER <br className='d-md-none' /> CENTRIC</h3>
    <img src={planet} alt='planet' />
    <p>
      We offer full transparency and connections to most of your existing social
      graphs.
    </p>
  </div>
);

const Features = () => {
  return (
    <section className='container-fluid features px-0 px-md-5' id='features'>
      <header className='content pt-5 px-4 px-md-0'>
        <h2 className='section-title' data-aos='fade-in'>
          <strong>How is Anokha different?</strong>
        </h2>
        <h2 className='section-description' data-aos='fade-in'>
          <span>
            While traditional social media profits by exploiting and harming
            users,
          </span>{' '}
          <strong>
            Anokha protects your well-being, helps in making friends, and
            rewards you!
          </strong>
        </h2>
      </header>
      <div className='row g-5 d-none d-md-flex px-4 px-md-0'>
        <div className='col-md-4'>{rewardCard}</div>
        <div className='col-md-4'>{communityCard}</div>
        <div className='col-md-4'>{userCard}</div>
      </div>
      <div className='d-md-none'>
        <SectionSlider items={[rewardCard, communityCard, userCard]} />
      </div>
    </section>
  );
};

export default Features;
